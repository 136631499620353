.CmsPage {
    [data-content-type='row'][data-appearance='full-width'] {
        box-sizing: border-box;

        > .row-full-width-inner {
            box-sizing: border-box;
            margin-left: auto;
            margin-right: auto;
            max-width: $layout__max-width;
            width: 100%;

            @include desktop {
                padding-inline: 40px;
            }
        }
    }

    //
    //  Mobile (style-m.css)
    //  _____________________________________________

    [data-content-type='row'][data-appearance='full-width'] {
        @include mobile {
            background-attachment: scroll !important;
        }
    }
}
