.pagebuilder-icon {
    -webkit-font-smoothing: antialiased;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    speak: none;
}

[class^='pagebuilder-icon-']:before,
[class*=' pagebuilder-icon-']:before {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    speak: none;
    text-decoration: inherit;
    text-rendering: auto;
    text-transform: none;
    vertical-align: middle;
}

.pagebuilder-icon-down:before {
    content: $icon-pagebuilder-caret-down__content;
}

.pagebuilder-icon-next:before {
    content: $icon-pagebuilder-caret-next__content;
}

.pagebuilder-icon-prev:before {
    content: $icon-pagebuilder-caret-prev__content;
}

.pagebuilder-icon-up:before {
    content: $icon-pagebuilder-caret-up__content;
}
