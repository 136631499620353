.CmsPage,
.CmsBlock-Wrapper {
    [data-appearance='collage-right'] {
        .pagebuilder-banner-wrapper {
            .pagebuilder-overlay {
                margin-left: auto;
            }
        }
    }
}
