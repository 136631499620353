.CmsPage,
.CmsBlock-Wrapper {
    [data-appearance='collage-left'] {
        .pagebuilder-slide-wrapper {
            .pagebuilder-overlay {
                margin-right: auto;
            }
        }
    }
}

