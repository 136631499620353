/**
 * This CSS resource incorporates links to font software which is the valuable copyrighted 
 * property of Monotype and/or its suppliers. You may not attempt to copy, install, redistribute, 
 * convert, modify or reverse engineer this font software. Please contact Monotype with 
 * any questions regarding Web Fonts: http://www.fonts.com
 */

@import url('https://fast.fonts.net/lt/1.css?apiType=css&c=d7b1bc7a-1600-4278-b3ef-4262c6794ee3&fontids=6005226,6005273');

/* Century Gothic Paneuropean W01 Regular */
@font-face{
    font-family: 'Century Gothic';
    font-style: normal;
    font-weight: 400;
    src:
    /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('../fonts/6005226/546e5c30-5063-4f3d-827b-e06ff2b81b7c.woff2') format('woff2'),
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
    url('../fonts/6005226/35580e31-25b6-42f3-aa05-94a4cf3ed9e2.woff') format('woff');
    font-display: swap;
}

/* Century Gothic Paneuropean W01 Bold */
@font-face{
    font-family: 'Century Gothic';
    font-style: normal;
    font-weight: bold;
    src:
    /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('../fonts/6005273/4e621b58-4586-429c-a0b2-9470ab4ae8c0.woff2') format('woff2'),
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
    url('../fonts/6005273/97e17f80-aaf1-4b74-a33b-e5dd730a26fd.woff') format('woff');
    font-display: swap;
}
