.CmsPage,
.CmsBlock-Wrapper {
    [data-content-type='banner'] > [data-element='link'],
    [data-content-type='banner'] > [data-element='empty_link'] {
        color: inherit;
        text-decoration: inherit;

        &:hover {
            color: inherit;
            text-decoration: inherit;
        }
    }

    .pagebuilder-banner-wrapper {
        background-clip: padding-box;
        border-radius: inherit;
        box-sizing: border-box;
        overflow-wrap: break-word;
        word-wrap: break-word;

        .pagebuilder-overlay {
            -moz-transition: background-color 500ms ease;
            -o-transition: background-color 500ms ease;
            -webkit-transition: background-color 500ms ease;
            box-sizing: border-box;
            padding: 30px;
            position: relative;
            transition: background-color 500ms ease;

            &.pagebuilder-poster-overlay {
                align-items: center;
                display: flex;
                justify-content: center;
            }

            &:not(.pagebuilder-poster-overlay) {
                max-width: 540px;
            }
        }

        &.jarallax {
            .video-overlay {
                z-index: 0;
            }
        }

        [data-element='content'] {
            min-height: 50px;
            overflow: auto;
        }

        .pagebuilder-banner-button {
            margin: 20px 0 0 0;
            max-width: 100%;
            text-align: inherit;
            word-break: break-word;
        }
    }

    //
    //  Mobile (style-m.css)
    //  _____________________________________________
    .pagebuilder-banner-wrapper {
        @include mobile {
            background-attachment: scroll !important;

            .pagebuilder-overlay:not(.pagebuilder-poster-overlay) {
                max-width: none;
            }
        }

    }
}
